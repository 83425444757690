import React from "react";

import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
	logsFont: {
		fontWeight:500,
    	lineHeight: 1.2,
	},
	logItem:{
		paddingTop:"5px",
		paddingBottom:"5px"
	}

}));

const TicketLogModal = ({ modalOpen, onClose,logsList }) => {
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={modalOpen} maxWidth="lg" onClose={handleClose} scroll="paper">
				{/* <List style={{ paddingTop: 0 }}>
					<div>
						{logsList.map(v => (
								<ListItem className={classes.logItem}>
									<ListItemText>
										<span className={classes.logsFont}>{v.logTypeMessage}</span>
									</ListItemText>
								</ListItem>
						))}
					</div>
				</List> */}

					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
							<TableHead>
							<TableRow>
								<TableCell>No</TableCell>
								<TableCell>Operation</TableCell>
								<TableCell>Detail</TableCell>
								<TableCell>Time</TableCell>
							</TableRow>
							</TableHead>
							<TableBody>
							{logsList.map((row,index) => (
								<TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row">{index + 1}</TableCell>
								<TableCell>{row.logTypeStr}</TableCell>
								<TableCell>
									<span dangerouslySetInnerHTML={{__html:row.logTypeMessage}} className={classes.logsFont}></span>
								</TableCell>
								<TableCell>{row.timeStr}</TableCell>
								</TableRow>
							))}
							</TableBody>
						</Table>
						</TableContainer>
		</Dialog>
	);
};

export default TicketLogModal;
