import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import RightMenu from "../RightMenu/index.js"

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 5,
		bottom: 5,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},

	waringClassName:{
		width: "20px",
		height: "20px",
		borderRadius:"50%",
		background:"#af4c65",
		color:"#fff",
		textAlign:"center",
	},
	sendStatusClass:{
		background:"#e6cb9a"
	},
	unrClass:{
		background:"#ffc0cb"
	}
}));

const TicketListItem = ({showRightMenu = false, ticket,changShowRightMenu }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		window.localStorage.removeItem("showRight")
		const handleClick = (event) => {
		  };
		  document.addEventListener('click', handleClick);
		return () => {
			isMounted.current = false;
			document.removeEventListener('click', handleClick);
			window.localStorage.removeItem("showRight")
		};
	}, []);

	const handleTicketMessage = async (item) => {
		history.push({
			pathname: `/tickets/${item.id}`,
			state: { 
				tags: item.tags,
				keycode:item.keycode
			 }
		  });
		  if(changShowRightMenu){
			changShowRightMenu(false)
			window.localStorage.removeItem("showRight")
		  }
	};

	const handleAcepptTicket = async (item) => {
		setLoading(true);
		try {
		 	await api.put(`/tickets/${item.id}`, {status: "open",userId: user?.id});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		handleSendTurnOrOff(item.id,0)
		history.push({
			pathname: `/tickets/${item.id}`,
			state: { 
				tags: item.tags,
				keycode:item.keycode
			 }
		  });
		  if(changShowRightMenu){
		    changShowRightMenu(false)
			window.localStorage.removeItem("showRight")
		  }
	};
	 /**
	    * 
	    * @param {*} id 
	    * @param {*} turnoroffNumber  1开启 0关闭
	    */
	  const handleSendTurnOrOff = async (id,turnoroffNumber) => {
	   try {
	    await api.post(`/tickets/turnOrOff`,{
			ticketId:id,
			turnoroffNumber,
	     });
	 } catch (err) {
	    toastError(err);
	   }
	  };
	const handleSelectTicket = async (item) => {
		history.push({
			pathname: `/tickets/${item.id}`,
			state: { 
				tags: item.tags,
				keycode:item.keycode
			 }
		  });
		  if(changShowRightMenu){
			changShowRightMenu(false)
			window.localStorage.removeItem("showRight")
		  }
	};

	const calBgColor = (tags) =>{
		return "linear-gradient(to right, "+tags.map(e=>e.color).join(",")+")";
	}

	const handleContextMenu = (event) => { // 显示自定义的右键菜单
		let flag = window.localStorage.getItem("showRight")
		event.preventDefault();
		if(!flag && !Boolean(showRightMenu)){
			if(changShowRightMenu){
			 changShowRightMenu(true)
			}
			window.localStorage.setItem("showRight",true)
			return
		}
		if(flag && Boolean(showRightMenu)){ //文案: 限制开启一个,请先关闭其他
			toast.error("Restrict opening one, please close the others first!")
			return
		}
	};

	return (
		<React.Fragment key={ticket.id}>
			<ListItem
				dense
				button
				onContextMenu={handleContextMenu}
				onClick={e => {
					if (ticket.status === "pending") return;
					handleSelectTicket(ticket);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem fila"}>
					<span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
				</Tooltip>

				<ListItemAvatar>
					<Avatar onClick={e => handleTicketMessage(ticket)} src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>

				<ListItemText
				    onClick={e => handleTicketMessage(ticket)} 
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								className={ticket.unreadMessages === -1 ? classes.unrClass : ''}
								component="span"
								variant="body2"
								color={ticket.unreadMessages === -1 ? '' : 'textPrimary'}
							>
							{
		(ticket.contact && ticket.contact.name + (ticket.user && `(${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name})`||' (undistributed)'))
}
							</Typography>
							{ticket.status === "closed" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"closed"}
									color="primary"
								/>
							)}
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}
							{/* {ticket.whatsappId && (
								<div className={classes.userTag} title={i18n.t("ticketsList.connectionTitle")}>{ticket.whatsapp?.name}</div>
							)} */}
							   {ticket.tags && ticket.tags.length==1 && (
								ticket.tags.map((element) => (
									<div key={element.id} className={classes.userTag} style={{ backgroundColor: element.color || "#7C7C7C" }} title={element.name || "Sem fila"}>{element.name}</div>
								))
							   )}
							    {ticket.tags && ticket.tags.length>1 && (
									<div className={classes.userTag} style={{ background: calBgColor(ticket.tags) }} title={ticket.tags.map(e=>e.name).join(",")}>{ticket.tags[ticket.tags.length-1].name+">>"}</div>
							   )}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<span className={(ticket.lastMessageBy === 0 && ticket.responseStatus > 0) ? classes.sendStatusClass : ''}>{ticket.lastMessage}</span>
								) : (
									<br />
								)}
							</Typography>
						   {
							ticket.responseStatus === 2 ? <Badge color="secondary" badgeContent={0}>
								<div className={classes.waringClassName}>!</div>
						  </Badge> : ticket.unreadMessages > 0 ? <Badge
						className={classes.newMessagesCount}
						badgeContent={ticket.unreadMessages}
						classes={{
							badge: classes.badgeStyle,
						}}
					/> : ''
						   }
						</span>
					}
				/>
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}

			</ListItem>
			<Divider variant="inset" component="li" />

		</React.Fragment>
	);
};

export default TicketListItem;
