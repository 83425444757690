import React from "react";
import { Checkbox, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';

  const useStyles = makeStyles(theme => ({
	rightMenuBox:{
		position:"absolute",
		backgroundColor: "#2576D2",
		right:"15px",
		zIndex:"10",
		top:"10px",
		cursor:"pointer"
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	  },
	  chose:{
		textAlign:"right",
		height: "30px",
		lineHeight: "30px",
		paddingRight: "5px",
		color: "#fff",
	  }
}));

const TicketsQueueSelect = ({queuesList,choseMenu,checkHandMenu,checkedList}) => {
	const classes = useStyles();

	const handleToggle = (value) => () => {
		let newChecked = [];
		if(newChecked.length <=0 && checkedList.length <=0){
			newChecked.push(value.id)
		}
		if(checkedList.length > 0 && !checkedList.includes(value.id)){
			newChecked = []
			newChecked.push(value.id)
		}
		if(checkedList.length > 0 && checkedList.includes(value.id)){
			newChecked = []
		}
		checkHandMenu(newChecked)
	};

	const handStopTag = (event)=>{
		event.stopPropagation()
	}

	const choseMenuTag = (event)=>{
		event.stopPropagation()
		choseMenu()
	}

	return (
			queuesList.length > 0 ?
			<Paper elevation={3}  className={classes.rightMenuBox}>
			 <div onClick={choseMenuTag} className={classes.chose}>Close</div><List className={classes.root} onClick={handStopTag}>
				{ queuesList.map((value) => {
					const labelId = `checkbox-list-label-${value.id}`;
					return (
					<ListItem key={value.id} pointerEvents="none" onClick={handleToggle(value)}>
						  <Checkbox checked={checkedList.indexOf(value.id) !== -1} />
						<ListItemText id={labelId} primary={value.name} />
					</ListItem>
					);
				})}
          </List>
		</Paper> : ''
	);
};

export default TicketsQueueSelect;
