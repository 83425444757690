import { toast } from "react-toastify";
export const NotifyMeTips = () => {
  if (!window.Notification) {
    // 先检查浏览器是否支持
    console.log("浏览器不支持通知");
  } else {
    console.log(Notification.permission, "Notification.permission");
    if (Notification.permission === "default") {
      // 检查用户曾经是否同意接受通知
      // 用户还未选择，可以询问用户是否同意发送通知
      Notification.requestPermission().then((res) => {
        /**
         * granted   用户同意授权
         * default   用户关闭授权 未刷新页面之前 可以再次请求授权
         * denied    用戶拒绝授权 浏览器不能显示通知
         */
        switch (res) {
          case "denied":
            toast.info(
              "Tips: Deny authorization, unable to display message notifications"
            ); //提示:拒绝授权,不能显示消息通知
            break;
        }
      });
    } else if (Notification.permission === "denied") {
      // denied 用户拒绝
      toast.info(
        "Tips: Deny authorization, unable to display message notifications"
      ); //提示:拒绝授权,不能显示消息通知
    }
  }
};

/**
 * 验证页面传递的是否有参数
 * @param   {Object}   v
 * @param   {String}   k
 * @returns Boolean
 */
export const CheckLocationParams = (v, k) => {
  return v && v.state && v.state[k] ? true : false;
};
