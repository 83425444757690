import React, { useState, useEffect, useContext } from "react";

import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Select,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import "./clientPoolModal.css"
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formDialogTitle: {
    textAlign: "center",
  },
  paper: {
    padding: "20px",
    textAlign: "center",
    color: "",
  },

}));

const ClientPoolModal = ({ open, onClose,formData,onSend }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [assignUser,setAssignUser] = useState("")
  const [assignUserList,setAssignUserList] = useState([])
  const [sendTime,setSendTime] = useState("")
  const { user } = useContext(AuthContext);
  const [tagName,setTagName] = useState("")
  const [disabledSend,setDisabledSend] = useState(false)

  useEffect(() => {
    if (open) {
      getTemplate();
      getAssignUser()
    }
  }, [open]);

  const getTemplate = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/messageTemplate", {
        params: {
          pageNumber: 1,
        },
      });
      setLoading(false);
      setTemplate(data.templates);
    } catch (err) {
      toastError(err);
    }
  };

  const getAssignUser = async ()=>{
    setLoading(true);
    try {
      const { data } = await api.get("/users", {
        params: {
          pageNumber: 1,
        },
      });
      setLoading(false);
      setAssignUserList(data.users);
    } catch (err) {
      toastError(err);
    }
  }

  const hardBlurInputTag = (ev)=>{
    setTagName(ev.target.value)
  }

  const hardChangeInputTag = (ev)=>{
    setTagName(ev.target.value)
  }

  const handleClose = () => {
    onClose();
  };

  const handleChangeTemplate = (e) => {
    setTemplateId(e.target.value ? Number(e.target.value) : '');
  };

  const clickSend = async()=>{
    if(!tagName){
        toast.error("Please enter tagName !")
        return
    }
    setLoading(true);
    setDisabledSend(true)
    try {
        const  {data} = await api.post("/tags/createOrGet",{
            tagName:tagName
        })
        let params = {
            tagId:data.id,
            batchName:data.name,
            sendTime:sendTime && sendTime!="" ? sendTime : null,
            templateId:templateId, //模版ID
            assignUser:assignUser && assignUser!=""? assignUser: null, //分配客服ID
            sendUser:user.id, //当前登录用户
            companyIds:formData.companyIds,
        }
      await api.post("/clientSendBatch",params);
      toast.success("success")
      setLoading(false);
      setDisabledSend(false)
      onSend()
    } catch (err) {
      toastError(err);
      setDisabledSend(false)
    }
  }

  const handleChangeAssignUser = (e)=>{
    setAssignUser(e.target.value);
  }

  const handleSendTimeChange = (e)=>{
    setSendTime(e.target.value)
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <DialogTitle>
          <div id={classes.formDialogTitle}>Send batch wa messages</div>
        </DialogTitle>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <div>Tag Name</div>
              <TextField
                id="outlined-name"
                label="Tag Name"
                value={tagName}
                onChange={hardChangeInputTag}
                onBlur={hardBlurInputTag}
                variant="outlined"
                />
            </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper className={classes.paper}>
              <div>Send Time</div>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField format="yyyy-mm-dd" type="date" id="outlinedBasicTime" value={sendTime}
                  onChange={handleSendTimeChange}
                  className={classes.textField}  variant="outlined" />
                </form>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
            <FormControl className={classes.formControl} variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">---message template---</InputLabel>
                        <Select
                        native
                        value={templateId}
                        label="---message template---"
                        onChange={handleChangeTemplate}>
                             <option value="" />
                        {
                        template && template.length > 0 ? template.map((v)=>{
                            return(
                                <option key={v.id} value={v.id}>{v.title}</option>
                            )}) : []
                        }
                        </Select>
                </FormControl>

            </Paper>
          </Grid>
          <Grid item xs={6}>
          <Paper className={classes.paper}>
          <FormControl className={classes.formControl} variant="outlined">
          <InputLabel  htmlFor="outlined-age-native-simple">---assign user---</InputLabel>
                        <Select
                        native
                        id="inputLabelUser"
                        value={assignUser}
                        label="---assign user---"
                        onChange={handleChangeAssignUser}>
                                <option value="" />
                        {
                        assignUserList && assignUserList.length > 0 ? assignUserList.map((v)=>{
                            return(
                                <option key={v.id} value={v.id}>{v.name}</option>
                            )}) : []
                        }
                        </Select>
                </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={disabledSend}
            variant="contained"
            onClick={clickSend}
            className={classes.btnWrapper}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClientPoolModal;
