import { toast } from "react-toastify";
import openSocket from "../services/socket-io";
let timer=null
export const assignTip = () => {
    if(timer){
      clearTimeout(timer)
    }
    const socket = openSocket();
    socket.on("assignTip", data => {
       toast.info(`${data.payload.loggedInUser.name} Contact person ${data.payload.ticket.contact.name} Assigned to ${data.payload.newUser.name}`, {
        autoClose:3000, 
      }) 
     })
     timer=setTimeout(() => {
      socket.disconnect();
     }, 3000);
}

