import React, {
  useState,
  useEffect,
  useContext,
} from "react";

import { useHistory } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MainContainer from "../../components/MainContainer";

import toastError from "../../errors/toastError";
import api from "../../services/api";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';

import ClientPoolModal from "../../components/ClientPoolModal";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';

import { format } from "date-fns";
import { AuthContext } from "../../context/Auth/AuthContext";

import ButtonGroup from "@material-ui/core/ButtonGroup";

import TableContainer from "@material-ui/core/TableContainer";

import Checkbox from "@material-ui/core/Checkbox";

import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import Typography from '@material-ui/core/Typography';

import "./clientPool.css"

import { DatePicker } from 'antd';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    width: 150,
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  propContainer: {
    width: 200,
    overflow: "hidden",
    margin: "20px auto 0",
  },
  propToggleHeader: {
    margin: "20px auto 10px",
  },
  height: "300px",
  root: {
    width: "100%",
    marginTop:"8px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  input: {
    display: 'none',
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  muiTableCellAlignCenter: {
    border: "1px solid red",
  },
  batchWa: {
    backgroundColor: "#009e0f",
    color: "#fff",
    height:"37px",
    marginRight:"15px",
    width:"110px",
  },
  search: {
    marginBottom: "15px",
    border: "1px solid #2576d2 !important",
    marginRight:"15px",
  },
  colorFont:{
    color:"#000",
    fontWeight:""
  },
  ulBox:{
    listStyleType: "none",
    padding: 0,
    display:"flex",
    alignItems: "center",
    marginLeft: "15px",
    fontSize: "16px",
    fontWeight:"bold"
  },
  senCount:{
    color:"red",
    margin:"0px 5px"
  },
  chips: {
		display: "flex",
		flexWrap: "wrap",
	},
}));

const ClientPool = () => {
  const rowsPerPage = 100
  const classes = useStyles();
  const history = useHistory();
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);//当前选中的表格 id
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [countPage,setCountPage] = useState(100)
  const [clientPoolModalOpen, setClientPoolModalOpen] = useState(false);
  const [clientStatusList,setClientStatusList] = useState([]);
  const [batchParams,setBatchParams] = useState({tagId:"",batchName:""});
  const [operStatus, setOperStatus] = useState("");
  const [clients, setClients] = useState([]);
  const [establishmentDateStart, setEstablishmentDateStart] = useState("");
  const [establishmentDateEnd, setEstablishmentDateEnd] = useState("");
  
  const [inputValueStart, setInputValueStart] = useState("");
  const [inputValueEnd, setInputValueEnd] = useState("");

  const [upFileValue,setUpFileValue] = useState("");

  const [selectedYearIds, setSelectedYearIds] = useState([]);

  const [yearsList,setYearsList] =  useState([]);
  
  let [noEstablishmentDate,setNoEstablishmentDate] = useState(false)
  let [selectedPagesQueueIds,setSelectedPagesQueueIds] = useState({});//分页拼接

  const [tags, setTags] = useState([]);
  const [checkAll,setCheckAll] = useState(false); //全选
  const { user } = useContext(AuthContext);
  const [formData,setFormData] = useState({
    tagId:null,
    batchName:null,
    companyIds:[],
  })

  let statusKey = {
    0:"new",
    1:"first around",
    2:"second around",
    3:"cold",
    4:"dead",
    99:"done",
  }

  useEffect(() => {
    Promise.all([getData(),getTagData(),getStatuData(),getYearsListData()]);
  }, []);

  const getYearsListData = ()=>{
   let y = new Date().getFullYear()
   let maxYear = 10
   let yearList = []
   for(let i = 0; i <= maxYear;i++ ){
       yearList.push({id:i,name:y-i})
   }
    setYearsList(yearList)
  }

  const getStatuData = ()=>{
    let stateList = Object.keys(statusKey).map((v,i)=>{
      return {
        name:statusKey[v],
        id:Number(v)
      }
    })
    setClientStatusList(stateList)
  }

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/clientPool", {
        params: {
          pageNumber:pageNumber,
          tagId:batchParams.tagId,
          years:selectedYearIds.length > 0 ? selectedYearIds.join(",") : null ,
          operStatus,
          establishmentDateStart:inputValueStart ? inputValueStart : '',
          establishmentDateEnd:inputValueEnd ? inputValueEnd : '',
          noEstablishmentDate:Boolean(noEstablishmentDate) ? 1 : 0
        },
      });
      let clients = data.clients.map((v) => {
        v.createdAt = format(new Date(v.createdAt), "dd/MM/yyyy");
        v.tagName = v.clientSendBatch.length > 0 ? getTagName(v.clientSendBatch) : ""
        return v;
      });
      setLoading(false);
      setClients(clients);
      setCountPage(data.count)
      let pageJoinIdList = []
      for(let i=1; i< (Math.ceil(data.count / rowsPerPage) + 1);i++){
        pageJoinIdList[i] = []
      } 
      setSelectedPagesQueueIds(pageJoinIdList)
      setSelectedQueueIds([]);
      setCheckAll(false)
    } catch (err) {
      toastError(err);
    }
  };

  const getTagData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/tags/cplist", {
        params: {
          pageNumber:1,
        },
      });
      let newData = data.map((v) => {
        v.createdAt = format(new Date(v.createdAt), "dd/MM/yyyy");
        return v;
      });
      setLoading(false);
      setTags(newData);
    } catch (err) {
      toastError(err);
    }
  };

  const handleEditUser = async(row) => {
    if(!row.whatsapp){
      toastError("No WhasApp Account !");
      return
    } 

    setLoading(true);
    try {
      let whatsappList = new Array(row.whatsapp)
      const { data } = await api.post("/clientPool/checkWhatApp", {
          whatsapp:whatsappList,
      });
      if(Object.keys(data.data).length <=0){
        toastError("No WhasApp Account !");
        return
      }
      const { data: ticket } = await api.post("/tickets", {
				contactId: data.data.id,
				userId: user.id,
				status: "open",
			});
			history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const getSelectedList = (id)=>{
    const selectedIndex = selectedPagesQueueIds[pageNumber].indexOf(id);
    if (selectedIndex === -1) {
      selectedPagesQueueIds[pageNumber] = selectedPagesQueueIds[pageNumber].concat(id);
    } else{
      selectedPagesQueueIds[pageNumber] = selectedPagesQueueIds[pageNumber].filter((v)=>{
        return v != id
      })
    }
    return getUniqueList(selectedPagesQueueIds[pageNumber])
  }

  const getUniqueList = (v)=>{
    let uniqueList  = new Set(v);
    let newArr = Array.from(uniqueList);
    return newArr
  }

  const setDeletingUser = async (row) => {
    setLoading(true);
    try {
      let clientData = {
        ...row
      }
      clientData.status = 4
      const { data } = await api.post("/clientPool/updateClientPool",clientData);
      toast.success("Update Success")
    } catch (err) {
      toastError(err);
    }
  };

  const handleClick = (id) => {
    let ids = getSelectedList(id)
    let newSelectedPagesQueueIds = Object.assign(selectedPagesQueueIds,{
        [pageNumber]: ids
      })

    let allChose =  selectedPagesQueueIds[pageNumber].length === clients.length ? true : false
    setCheckAll(allChose)
    setSelectedPagesQueueIds(newSelectedPagesQueueIds);
    changeSetSelectedQueueIdsFunc()
  };

  const changeSetSelectedQueueIdsFunc = ()=>{
     let list = []
     for(let i in selectedPagesQueueIds){
      list = list.concat(selectedPagesQueueIds[i])
     }
     let ids = getUniqueList(list)
     setSelectedQueueIds(ids);
  }

  const handleClickAll = (event) => {
    let ids = clients.map((v) => {
      return v.id;
    });
    if(checkAll){
      selectedPagesQueueIds[pageNumber] = []
      setCheckAll(false)
    }else{
      selectedPagesQueueIds[pageNumber] = ids
      setCheckAll(true)
    }
    setSelectedPagesQueueIds(selectedPagesQueueIds);
    changeSetSelectedQueueIdsFunc()
  };

  const handleChangeBatch = (e) => {
    let flag = tags.find((v)=>{
      return v.id === Number(e.target.value)
    })
    setBatchParams({
      tagId:flag && flag.id ? flag.id : '',
      batchName:flag && flag.name ? flag.name : ''
    })
  };

  const handleChangeOperStatus = (e) => {
    setOperStatus(e.target.value);
  };

  const isSelected = (id) => selectedQueueIds.indexOf(id) !== -1;

  const searchButton = () => {
    getData();
  };

  const handleClosePoolModal = () => {
       setClientPoolModalOpen(false);
  };

  const handleSendPoolModal = ()=>{
    setClientPoolModalOpen(false);
    getData()
    setCheckAll(false)
  }

  const batchWaButton = () => {
    let params = {
      tagId:batchParams.tagId,
      batchName:batchParams.batchName,
      companyIds:selectedQueueIds || [],
    }
    if(selectedQueueIds.length <=0){
      toast.warning("Please select the company that needs to be sent!")
      return
    }
    setFormData(params)
    setClientPoolModalOpen(true);
  };

  const hardChangePage = async(event,value)=>{
    if(value === pageNumber){
      return
    }
    setLoading(true);
    try {
      const { data } = await api.get("/clientPool", {
        params: {
          pageNumber:value,
          tagId:batchParams.tagId,
          years:selectedYearIds.length > 0 ? selectedYearIds.join(",") : null ,
          operStatus,
          establishmentDateStart:inputValueStart ? inputValueStart : '',
          establishmentDateEnd:inputValueEnd ? inputValueEnd : '',
          noEstablishmentDate:Boolean(noEstablishmentDate) ? 1 : 0
        },
      });
      data.clients = data.clients.map((v) => {
        v.createdAt = format(new Date(v.createdAt), "dd/MM/yyyy");
        v.tagName = v.clientSendBatch.length > 0 ? getTagName(v.clientSendBatch) : ""
        return v;
      });
      setLoading(false);
      setClients(data.clients);
      setCountPage(data.count)
      setPageNumber(value)
      let allChose =  selectedPagesQueueIds[value].length === data.clients.length ? true : false
      setCheckAll(allChose)
    } catch (err) {
      toastError(err);
    }
  }

  const getTagName  = (data)=>{
    let list = data.map((v)=>{
      return v.batchName
    }).join(",")
    return list
  }

  const handleSwitchChange = (ev=>{
    let establishmentDate = noEstablishmentDate
    if(!establishmentDate){
      setEstablishmentDateStart("");
      setEstablishmentDateEnd("");
      setInputValueStart("");
      setInputValueEnd("");
    }
    setNoEstablishmentDate(!establishmentDate)
  })
 
  const onChange = (dates, dateStrings) => {
    if(dates){
      setEstablishmentDateStart(dates[0]);
      setInputValueStart(dateStrings[0]);
      setEstablishmentDateEnd(dates[1]);
      setInputValueEnd(dateStrings[1]);
    }else{
      setEstablishmentDateStart("");
      setEstablishmentDateEnd("")
      setInputValueStart("");
      setInputValueEnd("");
    }
  };

  const changeIputFile = async (ev)=>{
    let file = ev.target.files[0];
    let fileSplitList = file.name.split('.')
    let fileSuffix = fileSplitList[fileSplitList.length - 1]
    if(fileSuffix && fileSuffix.toLowerCase() != 'xlsx'){
      toast.warning("Please upload the xlsx format file!")
      return
    }
    ev.preventDefault();
    const formData = new FormData();
    formData.append("medias", file);
    setLoading(true);
    try {
      const { data } = await api.post("/clientPool/uploadFile", formData);
      if(data.code !=200){
        toast.error("File import failed!")
        setLoading(false);
        setUpFileValue("")
        return
     }
     setLoading(false);
     setUpFileValue("")
     toast.success(data.message);
     getData()
    } catch (err) {
      toastError(err);
      setUpFileValue("")
      setLoading(false);
    }
  }

  const handleChangeYears = (ev)=>{
    setSelectedYearIds(ev.target.value)
  }

  return (
    <MainContainer id="slicesclass">
      <Paper className={classes.paper} id="slicesclass">
        <ClientPoolModal
          open={clientPoolModalOpen}
          onClose={handleClosePoolModal}
          onSend = {handleSendPoolModal}
          formData={formData}
          aria-labelledby="form-dialog-title"
        />

        <div className={classes.root} id="padi" noValidate autoComplete="off">
          <FormControlLabel
            control={
              <Switch
                checked={noEstablishmentDate}
                onChange={handleSwitchChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Unknown Date of Establishment"
          />

          <Grid container spacing={3} direction="row">
              {
                !noEstablishmentDate ?    <Typography component="div">
                        <div  className={classes.root} id="flagdic" noValidate autoComplete="off">
                        <DatePicker.RangePicker
                        id="srug"
                    onChange={onChange}
                    format="MM-DD"
                    value={[establishmentDateStart, establishmentDateEnd]}
                  />
                        </div>
              </Typography> : ''
              }

            <Grid item>
               <FormControl size="small" variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-years-native-simple">--years--</InputLabel>
                <Select
                multiple
                labelWidth={60}
                value={selectedYearIds}
                onChange={handleChangeYears}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {yearsList.map(queue => (
                  <MenuItem key={queue.id} value={queue.name}>
                    <Checkbox  color="default" checked={selectedYearIds.indexOf(queue.name) > -1} />
                    <ListItemText primary={queue.name} />
                  </MenuItem>
                ))}
              </Select>
        </FormControl> 

            </Grid>

            <Grid item>
               <FormControl size="small" variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">--client Status--</InputLabel>
                <Select
                  native
                  id="topend10"
                  value={operStatus}
                  onChange={handleChangeOperStatus}
                  label="Client Status">
                  <option value="" />
                  {clientStatusList.map((v) => {
                            return (
                              <option key={v.id} value={v.id}>{v.name}</option>
                            );
                          })}
                </Select>
              </FormControl> 
            </Grid>

            <Grid item>
               <FormControl size="small" variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">--tag--</InputLabel>
                <Select
                  native
                  value={batchParams.tagId}
                  onChange={handleChangeBatch}
                  label="Tag">
                  <option value="" />
                  {tags.map((v) => {
                            return (
                              <option key={v.id} value={v.id}>{v.name}</option>
                            );
                          })}
                </Select>
              </FormControl> 
            </Grid>

            <Grid item xs={2}>
              <ButtonGroup
                color="primary"
                aria-label="vertical outlined primary button group"
                style={{marginTop:"10px"}}
              >
                <Button
                  onClick={searchButton}
                  variant="outlined"
                  className={classes.search}
                >
                  Search
                </Button>
                <Button
                  onClick={batchWaButton}
                  variant="contained"
                  color="primary"
                  className={classes.batchWa}>
                  Batch Wa
                </Button>


                   <div>
                  <input
                    onChange={changeIputFile}
                    value={upFileValue}
                    accept=".xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                    Import
                    </Button>
                  </label>
                </div>

              </ButtonGroup>
            </Grid>
          </Grid>
        </div>

            <ul className={classes.ulBox}> 
                <li>Selected</li>
                <li className={classes.senCount}>{selectedQueueIds.length}</li>
                <li>Records</li>
            </ul>

        <Divider variant="middle" />

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
          <TableHead>
            <TableRow>
            <TableCell  padding="checkbox">
                  <Checkbox checked={checkAll}  onClick={(event) => handleClickAll(event)} />
              </TableCell>
              <TableCell  align="center">ClientsName</TableCell>

              <TableCell  align="center">
               Phone
              </TableCell>

              <TableCell  align="center">
               Contacts
              </TableCell>
              <TableCell  align="center">
               WhatsApp
              </TableCell>
              <TableCell  align="center">
              EstablishmentDate
              </TableCell>              
              <TableCell  align="center">
              channel
              </TableCell>
              <TableCell  align="center">
              Status
              </TableCell>
              <TableCell  align="center">
              Tag
              </TableCell>
              <TableCell  align="center">
              FetchTime
              </TableCell>
              <TableCell  align="center">
              Actions
              </TableCell>
            </TableRow>
          </TableHead>
                        {

clients && clients.length > 0 ? <TableBody>
{
  clients.map((row, index) => {
    const isItemSelected = isSelected(row.id);
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}>
        <TableCell padding="checkbox" >
           <Checkbox checked={isItemSelected} onClick={(event) => handleClick(row.id)} />
        </TableCell>
        <TableCell align="center" >
          <div className="dicefn">
            <div>{row.companyName}</div>
            <div>{row.companyName1}</div>
          </div>
        </TableCell>
        <TableCell  align="center">{row.phone}</TableCell>
        <TableCell  align="center">{row.contacts}</TableCell>
        <TableCell  align="center">{row.whatsapp}</TableCell>
        <TableCell  align="center">{row.establishmentDate}</TableCell>
        <TableCell  align="center">{row.channel}</TableCell>
        <TableCell  align="center">{statusKey[row.operStatus]}</TableCell>
        <TableCell  align="center">{row.tagName}</TableCell>
        <TableCell  align="center">{row.createdAt}</TableCell>
    <TableCell  align="center">
      <IconButton
      style={{marginLeft:"10px"}}
        size="small"
        color="primary"
        onClick={() => handleEditUser(row)}>
        Wa
      </IconButton>

      <IconButton
      style={{marginLeft:"50px"}}
      color="primary"
        size="small"
        onClick={(e) => {setDeletingUser(row)}}>
       Die
      </IconButton>
    </TableCell>
      </TableRow>
    );
  })}
  {loading && <TableRowSkeleton avatar columns={4} />}
</TableBody> : ""
 }
 </Table>
        {
           <div id="tabFooterPage">
              <Pagination count={countPage <= 100 ?  1 : Math.ceil(countPage / rowsPerPage)} variant="outlined" page={pageNumber} onChange={hardChangePage} className="paginationClassName" shape="rounded" />
           </div>
        }
        
        </TableContainer>

       {
        clients && clients.length == 0 ? <div id="notDataBody">
           <div id="notData">No Data</div>
        </div> : ''
       } 
      </Paper>
    
    </MainContainer>
  );
};

export default ClientPool;
