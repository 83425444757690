import React, { useState, useEffect, useReducer, useContext,useRef } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import RightMenu from "../RightMenu/index.js"

import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));


const reducer = (state, action) => {
	let user = action.user
	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) {
					state.unshift(state.splice(ticketIndex, 1)[0]);
				}
			} else {
				state.push(ticket);
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		const ticketObject = state.find(t => t.id === ticketId);

		if (ticketIndex !== -1 && user.id == ticketObject.user?.id) {
			state[ticketIndex].unreadMessages = 0;
		}
		console.log("RESET_UNREAD:"+ticketId);
		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			state.unshift(ticket);
		}
		console.log("UPDATE_TICKET:"+ticket.id);
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			state.unshift(state.splice(ticketIndex, 1)[0]);
		} else {
			state.unshift(ticket);
		}
		console.log("UPDATE_TICKET_UNREAD_MESSAGES:"+ticket.id);
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}
		console.log("UPDATE_TICKET_CONTACT:"+contact.id);
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}
		console.log("DELETE_TICKET:"+ticketId);
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

	const TicketsList = (props) => {
		const { status, searchParam, showAll, users,selectedTagIds, updateCount, style, selectQueueParams } = props;
		const classes = useStyles();
		const [pageNumber, setPageNumber] = useState(1);
		const { user } = useContext(AuthContext);
		const [ticketsList, dispatch] = useReducer(reducer,[]);

		const [showRightMenu, setShowRightMenu] = useState(false);
		const [selectedQueueIds,setSelectedQueueIds] = useState([])
		const [checkedList, setCheckedList] = useState([]);
		const [queuesList, setQueuesList] = useState([]);

		// const { user } = useContext(AuthContext);
		// const [ticketsList, dispatch] = useReducer((state) => reducer({...state,user}), []);

		useEffect(() => {
			dispatch({ type: "RESET",user:user });
			setPageNumber(1);
		}, [status, searchParam, dispatch, showAll, selectedQueueIds, selectedTagIds, selectQueueParams]);
		let params = {
			pageNumber,
			searchParam,
			status,
			showAll,
			tags: JSON.stringify(selectedTagIds),
			users: JSON.stringify(users),
			queueIds:JSON.stringify(selectedQueueIds)
		}

		if(selectQueueParams && parseInt(selectQueueParams.assignUser) > -1){
			params.assignUser = selectQueueParams.assignUser
		}
		if(selectQueueParams && parseInt(selectQueueParams.serviceBy) > -1){
			params.serviceBy =  selectQueueParams.serviceBy
		}

		const { tickets, hasMore, loading } = useTickets(params);

	useEffect(() => {
		if (!status && !searchParam) return;
		dispatch({
			type: "LOAD_TICKETS",
			payload: tickets,
			user:user,
		});
	}, [tickets]);


	useEffect(() => {
		(async () => {
		  try {
			const { data } = await api.get("/queue");
			let queuesList = data.sort((a,b)=>{
				return a.id - b.id
			})
			setQueuesList(queuesList)
		  } catch (err) {
			toastError(err);
		  }
		})();
	  }, []);


	useEffect(() => {
		const socket = openSocket();

		const shouldUpdateTicket = ticket => (!searchParam || searchParam.length<3) &&
			(!ticket.userId || ticket.userId === user?.id || showAll);

		const notBelongsToUserQueues = ticket =>
			ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					user:user,
					payload: data.ticketId,
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					user:user,
					payload: data.ticket,
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({ type: "DELETE_TICKET",user:user, payload: data.ticket.id });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET",user:user, payload: data.ticketId });
			}
		});

		socket.on("appMessage", data => {
			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
					user:user,
				});
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
					user:user,
				});
			}
		});
		
		return () => {
			socket.disconnect();
		};
	}, [status, searchParam, showAll,selectedTagIds, users, user, selectedQueueIds]);

	useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.filter(t=>{
		if(selectQueueParams && parseInt(selectQueueParams.assignUser) > -1){
			return t.userId==parseInt(selectQueueParams.assignUser);
		}else if(selectQueueParams && parseInt(selectQueueParams.serviceBy) > -1){
			return t.serviceBy == parseInt(selectQueueParams.serviceBy);
		}else{
			return true;
		}
	}).length);
    }
	if(ticketsList.length > 0 && selectedQueueIds.length > 0 &&  showRightMenu){
		setShowRightMenu(false)
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList, selectQueueParams]);


	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			e.currentTarget.scrollTop = scrollTop - 100;
			loadMore();
		}
	};

	const getKeyCodeList = (ticketsList)=>{
		let list = []
		let dataList = ticketsList.sort((a,b)=>{
			return a.ticketId=b.ticketId?(b.updatedAt-a.updatedAt>0?1:-1):((b.responseStatus - a.responseStatus)*100+((selectQueueParams && parseInt(selectQueueParams.assignUser) > -1)?(a.lastMessageBy-b.lastMessageBy):0)*10+(b.updatedAt-a.updatedAt>0?1:-1))
		})
		dataList.forEach((v)=>{
			if(v.messages && v.messages.length > 0){
				v.messages.forEach((value)=>{
						list.push({
							keycode:value.id,  //保留当前信息 messageId
							lastMessage:value.body,  //保留当前信息内容

							id:v.id, //更换ID ,message信息记录参数
							status:v.status,
							lastMessageBy:v.lastMessageBy,
							tags:v.tags,
							userId:v.userId,
							contact:v.contact,
							queue:v.queue,
							updatedAt:v.updatedAt,
						})
					})
			}else{
				list.push({
					keycode:"",  //保留当前信息 messageId
					lastMessage:v.lastMessage,  //保留当前信息内容

					id:v.id, //更换ID ,message信息记录参数
					status:v.status,
					lastMessageBy:v.lastMessageBy,
					tags:v.tags,
					userId:v.userId,
					contact:v.contact,
					queue:v.queue,
					updatedAt:v.updatedAt,
				})
			}
		})
		return list
	}

	const changShowRightMenu = (value)=>{
		setShowRightMenu(value)
	}

	const choseMenu = ()=>{
		setShowRightMenu(false)
		setSelectedQueueIds([''])
		setCheckedList([])
		window.localStorage.removeItem("showRight")
	}

	const checkHandMenu = (value)=>{
		let v = value.length > 0 ? value[0] : ''
		setSelectedQueueIds([v])
		setCheckedList(value)
	}

	return (
    <Paper className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle") }
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (
						<>
							{
								searchParam && ticketsList.length > 0 ? getKeyCodeList(ticketsList).map(ticket => (
									<TicketListItem  ticket={ticket} key={ticket.keycode ? ticket.keycode : (ticket.id + ticket.lastMessage)} />
								)) :
							ticketsList.filter(t=>{
								if(selectQueueParams && parseInt(selectQueueParams.assignUser) > -1){
									return t.userId==parseInt(selectQueueParams.assignUser);
								}else if(selectQueueParams && parseInt(selectQueueParams.serviceBy) > -1){
									return t.serviceBy == parseInt(selectQueueParams.serviceBy);
								}else{
									return true;
								}
							}).map(ticket => (
								<TicketListItem  className={classes.ticketRightMenu} showRightMenu={showRightMenu} changShowRightMenu={changShowRightMenu} ticket={ticket} key={ticket.id} />
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
				{
					showRightMenu && (status === "open" || status === 'pending')  ? <RightMenu  queuesList={queuesList} checkedList={checkedList} choseMenu={choseMenu} checkHandMenu={checkHandMenu} />  : ''
				}
			</Paper>
    </Paper>
	);
};

export default TicketsList;
