import { useState, useEffect } from "react";
import { getHoursCloseTicketsAuto } from "../../config";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const 
useTickets = ({
    searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    withUnreadMessages,
    assignUser,
    serviceBy,
    tags
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    let params =  {
                        searchParam,
                        pageNumber,
                        status,
                        date,
                        showAll,
                        queueIds,
                        withUnreadMessages,
                        tags
                    }

                    if(status === "open" && Number(assignUser) > -1 && typeof assignUser === 'number'){
                        params.assignUser = assignUser
                        params.queueIds = "[]"
                    }

                    if(status === "pending" && Number(serviceBy) > -1 &&  typeof serviceBy === 'number'){
                        params.serviceBy = serviceBy
                        params.queueIds = "[]"
                    }

                    if(queueIds === '[""]'){
                         params.queueIds = "[]"
                    }

                    const { data } = await api.get("/tickets", {params})
                    setTickets(data.tickets)

                    let horasFecharAutomaticamente = getHoursCloseTicketsAuto(); 

                    if (status === "open" && horasFecharAutomaticamente && horasFecharAutomaticamente !== "" &&
                        horasFecharAutomaticamente !== "0" && Number(horasFecharAutomaticamente) > 0) {

                        let dataLimite = new Date()
                        dataLimite.setHours(dataLimite.getHours() - Number(horasFecharAutomaticamente))

                        data.tickets.forEach(ticket => {
                            if (ticket.status !== "closed") {
                                let dataUltimaInteracaoChamado = new Date(ticket.updatedAt)
                                if (dataUltimaInteracaoChamado < dataLimite)
                                    closeTicket(ticket)
                            }
                        })
                    }

                    setHasMore(data.hasMore)
                    setCount(data.count)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            const closeTicket = async(ticket) => {
                await api.put(`/tickets/${ticket.id}`, {
                    status: "closed",
                    userId: ticket.userId || null,
                })
            }

            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        searchParam,
        pageNumber,
        status,
        date,
        showAll,
        queueIds,
        withUnreadMessages,
        assignUser,
        tags,
        serviceBy
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;