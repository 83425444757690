import React, { useState, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	  flexDireWorp: {
		display: "flex",
		alignItems: "center",
		flexWrap:"wrap",
		marginTop:"15px",
	  },
	  flexDire:{
		display: "flex",
		alignItems: "center",
	  },
	  leftDiv:{
		color:"#3e85d7",
		fontSize:"18px",
		fontWeight:"bold",
	  },
	  rigDiv:{
		marginLeft:"20px",
		color:"black"
	  },
	  paddingClassBox:{
		paddingRight:"8px",
		marginLeft:"15px",
		width:"31.9%",
	  },
	  paddingClass:{
		padding:"15px 10px",
		marginBottom:"15px",
	  }
}))

const Dashboard = () => {
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
    const [ticketsToday, setTicketsToday] = useState([]);
    const [inserviceCnt, setInserviceCnt] = useState(0);
	const [waitingCnt, setWaitingCnt] = useState(0);
	const [closedCnt, setClosedCnt] = useState(0);
	const [itemList,setItemList] = useState([]);

	useEffect(() => {
		setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    const { data } = await api.get("/dashboard")
                    setTicketsToday(data.ticketsToday)
                    setInserviceCnt(data.inserviceCnt)
                    setWaitingCnt(data.waitingCnt)
                    setClosedCnt(data.closedCnt)
					getGroupByAssigner()
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }
            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
	}, [

    ])


	let getGroupByAssigner = async ()=>{
		const {data} = await api.get("/dashboard/groupByAssigner")
		setItemList(data.itemList)
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.inAttendance.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{inserviceCnt}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.waiting.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{waitingCnt}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.closed.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{closedCnt}
								</Typography>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart ticketsToday={ticketsToday}/>
						</Paper>
					</Grid>

					{
					itemList && itemList.length > 0 ? <Grid container spacing={1}>
						  { <div className={classes.flexDireWorp}>
							  {
								   itemList.map((v)=>{
									return (
										<Grid className={classes.paddingClassBox}>
											<Paper className={classes.paddingClass}>
											<Typography className={classes.flexDire} color="textSecondary">
									  <div className={classes.leftDiv}>
										  {v.name}
									  </div>
									  <div className={classes.rigDiv}>
										  <p>
											  <span>In service :</span>
											  <span> {v.serviceCnt}</span>
										  </p>
										  <p>
											  <span> &gt; 15Mins :</span>
											  <span> {v.warnCnt}</span>
										  </p>
										  <p>
											  <span>avgResp :</span>
											  <span> {v.avgHumanRespTime}</span>
										  </p>
									  </div>
							        </Typography>
											</Paper>
										</Grid>
									)
								  })
							  }
						  </div> }
				  </Grid> : ''
				}
				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard