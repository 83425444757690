import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import TicketLogModal from "../TicketLogModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { format } from "date-fns";
import { toast } from "react-toastify";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [TicketLogModalOpen, setTicketLogModalOpen] = useState(false);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [logsList,setLoginsList] = useState([])
	const logsMessageMap = {
		0:{
			paragraph0:"contact\u00A0",
			// * Open ticket - 
			paragraph1:"\u00A0open a ticket\u00A0",
		  },
		1:{
			// Transfer to human be - 
			paragraph0:"*onestep-bot tansfer the ticket 2 customer service\u00A0",
		  },
		2:{
			paragraph0:"\u00A0",
			// * Accept ticket - 
			paragraph1:"\u00A0accepted ticket\u00A0",
		  },
		3:{
			paragraph0:"\u00A0",
			//* transfer ticket - 
			paragraph1:"\u00A0transferred the ticket from \u00A0",
			paragraph2:"\u00A0to \u00A0",
		  },
		4:{
			paragraph0:"\u00A0",
			// * Resolve - 
			paragraph1:"\u00A0resolved the ticket\u00A0",
		  },
		5:{
			paragraph0:"\u00A0",
			// * Reopen -
			paragraph1:"\u00A0reopened the ticket\u00A0",
		 }
	}

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	const handleTicketLogModal = async () => {
		try{
			const { data } = await api.get(`/ticketLog?ticketId=${ticket.id}&pageNumber=1`);
			if(data.ticketLogs.length <= 0){
				toast.info("Tip: The current user currently has no history records!") //提示:当前用户暂无历史记录
				return
			}
		let list = data.ticketLogs.map((v)=>{
			v.logTypeMessageMap = logsMessageMap[v.logType]
			return v
	   }).map((item)=>{
		  if(item.logType === 0){
			item.logTypeStr = "Open"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 + `<span style="color:red">${(verifyParams(item.operUserId,item.operUser) ? item.operUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph1 
			//  + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  if(item.logType === 1){
			item.logTypeStr = "B2M"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 
			//  + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  if(item.logType === 2){ 
			item.logTypeStr = "Accept"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 + `<span style="color:red">${(verifyParams(item.fromUserId,item.fromUser) ? item.fromUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph1
			//   + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  if(item.logType === 3){
			item.logTypeStr = "Transfer"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 + `<span style="color:red">${(verifyParams(item.fromUserId,item.fromUser) ? item.fromUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph1 + `<span style="color:red">${(verifyParams(item.fromUserId,item.fromUser) ? item.fromUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph2 + `<span style="color:red">${(verifyParams(item.toUserId,item.toUser) ? item.toUser.name : verifyParams(item.operUserId,item.operUser) ? item.operUser.name : '???')}</span>` 
			//  + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  if(item.logType === 4){
			item.logTypeStr = "Resolve"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 + `<span style="color:red">${(verifyParams(item.toUserId,item.toUser) ? item.toUser.name : verifyParams(item.operUserId,item.operUser) ? item.operUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph1
			//   + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  if(item.logType === 5){
			item.logTypeStr = "Reopen"
			item.timeStr = format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')
			item.logTypeMessage = item.logTypeMessageMap.paragraph0 + `<span style="color:red">${(verifyParams(item.fromUserId,item.fromUser) ? item.fromUser.name : '???')}</span>` + item.logTypeMessageMap.paragraph1
			//   + '\u00A0 \u00A0' + format(new Date(item.createdAt),'yyyy/MM/dd HH:mm:ss')
		  }
		  return item
	   })
			  setLoginsList(list)
			  setTicketLogModalOpen(true);
			  handleClose();

		} catch (err) {
			toastError(err);
		}
	};

	const  verifyParams = (id,obj)=>{
		return id && Object.keys(obj).length > 0 ? true : false
	}

	const handleCloseTicketLogModal = () => {
		if (isMounted.current) {
			setTicketLogModalOpen(false);
			setLoginsList([])
		}
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{ticket.status === "closed" && (
						<>
						<MenuItem onClick={handleTicketLogModal}>
							{i18n.t("ticketOptionsMenu.ticketLog")}
						</MenuItem>
						</>
				)
				}
				{ticket.status != "closed" && (
					<>
					<MenuItem onClick={handleOpenTransferModal}>
						{i18n.t("ticketOptionsMenu.transfer")}
					</MenuItem>
					<Can
						role={user.profile}
						perform="ticket-options:deleteTicket"
						yes={() => (
							<MenuItem onClick={handleOpenConfirmationModal}>
								{i18n.t("ticketOptionsMenu.delete")}
							</MenuItem>
						)}
					/>
					<MenuItem onClick={handleTicketLogModal}>
						{i18n.t("ticketOptionsMenu.ticketLog")}
					</MenuItem>
					</>
				)
				}
				
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
					ticket.id
				} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
					ticket.contact.name
				}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticketWhatsappId={ticket.whatsappId}
			/>
				<TicketLogModal
				modalOpen={TicketLogModalOpen}
				logsList={logsList}
				onClose={handleCloseTicketLogModal}
			/>
		</>
	);
};

export default TicketOptionsMenu;
